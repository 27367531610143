import React from "react";
import PropTypes from "prop-types";
import SEO from "../components/SEO";
import { graphql } from "gatsby";
import Header from "../components/Header";
import Footer from "../components/Footer";

const NotFoundPage = ({ data, location }) => {
  return (
    <div className="sticky-footer-layout relative">
      <SEO title="404" description="Page not found" location={location} />
      <img
        src={data.prismicGlobal.data.fourohfour_image.url}
        className="hidden md:block absolute w-full h-full object-cover object-bottom z-10"
        alt="Page not found"
      />
      <div className="relative pb-24 xl:pb-36 z-20">
        <img
          src={data.prismicGlobal.data.fourohfour_image.url}
          className="md:hidden absolute w-full h-full object-cover object-bottom z-10"
          alt="Page not found"
        />
        <div className="relative z-20">
          <Header pageTheme="dark" backLink="/" is404 />
          <h1 className="text-center text-lg lg:text-xl xl:text-2xl text-white">
            Page not found
          </h1>
        </div>
      </div>

      <div className="sticky-footer-layout__footer text-white relative z-20 bg-black md:bg-transparent">
        <Footer />
      </div>
    </div>
  );
};

NotFoundPage.propTypes = {
  location: PropTypes.object.isRequired
};

export default NotFoundPage;

export const query = graphql`
  query {
    prismicGlobal {
      data {
        fourohfour_image {
          url
        }
      }
    }
  }
`;
